<template>
  <div id="labels-page">
    <app-box width="large">
      <template #legend>Tasks</template>
      <template #title>Manage labels</template>
      <div class="mb-4 md:mb-6" :class="[cLabels.length ? ['md:flex md:justify-end'] : []]">
        <p v-if="!cLabels.length || !minimal" class="text-sm md:text-base text-gray-700 leading-snug">
          Improve your workflow by using labels to categorize your tasks.
          Once you have created a label on this page you can apply it to any task from the <router-link to="/tasks" class="underline hover:no-underline">active tasks page</router-link>, or the <router-link to="/backlog" class="underline hover:no-underline">backlog page</router-link>.
        </p>
        <div :class="[cLabels.length ? [minimal ? ['-mt-28 md:-mt-20 text-center'] : ['mt-4 md:mt-0 md:pl-8 md:self-end']] : [minimal ? ['text-center -mt-28 md:-mt-20'] : ['text-center mt-8']]]">
          <app-button :class="[!minimal ? ['relative md:pl-10'] : []]" type="add" @click="openModal()">
            <svg v-if="!minimal" xmlns="http://www.w3.org/2000/svg" class="hidden md:inline absolute left-4 top-3.5 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <span :class="{ 'md:ml-1.5': !minimal}">New label</span>
          </app-button>
        </div>
      </div>
      <app-table v-if="cLabels.length" class="-mb-8 md:mb-0 -mx-5 md:mx-0" :class="minimal ? ['-mt-1 md:-mt-14'] : []">
        <app-thead>
          <app-tr>
            <app-th>
              {{ cLabels.length }} label{{ cLabels.length !== 1 ? 's' : '' }}
            </app-th>
            <app-th>
              Color
            </app-th>
            <app-th>
              Appearance
            </app-th>
            <app-th />
          </app-tr>
        </app-thead>
        <app-tbody>
          <transition-group move-class="transition-transform duration-500" enter-active-class="transition-colors duration-slow" enter-from-class="bg-yellow-200" enter-to-class="bg-transparent" leave-active-class="transition-opacity duration-150" leave-from-class="opacity-100" leave-to-class="opacity-0">
            <tr v-for="label in cLabels" :id="label.lastAdded ? 'last-added' : null" :key="label.uuid">
              <app-td>
                <app-td-content>
                  <template #first>{{ label.name }}</template>
                  <template #second>{{ label.description || '-' }}</template>
                </app-td-content>
              </app-td>
              <app-td>
                <app-td-content>
                  <template #single><span class="capitalize">{{ label.color }}</span></template>
                </app-td-content>
              </app-td>
              <app-td>
                <app-badge :color="label.color" :description="label.description || ''">{{ label.name }}</app-badge>
              </app-td>
              <app-td class="w-1">
                <app-dropdown :options="label.options" entity="label" :count-url="`api/itemlabels/count?labelUuid=${label.uuid}`" @click="handleChoice(label.uuid, $event)" />
              </app-td>
            </tr>
          </transition-group>
        </app-tbody>
      </app-table>
      <transition enter-active-class="transition-opacity duration-500" enter-from-class="opacity-0" enter-to-class="opacity-100" leave-active-class="transition-opacity duration-500" leave-from-class="opacity-100" leave-to-class="opacity-0">
        <app-modal v-if="showModal" @close="closeModal">
          <template #title>
            {{ editUuid ? "Edit label" : "Create new label" }}
          </template>
          <app-form @submit.prevent="submitForm">
            <app-form-field>
              <app-label id="name">
                Name
              </app-label>
              <app-input id="name" v-model.trim="form.fields.name" maxlength="45" />
            </app-form-field>
            <app-form-field>
              <app-label id="description">
                Description
              </app-label>
              <app-input id="description" v-model.trim="form.fields.description" maxlength="100" />
            </app-form-field>
            <app-form-field>
              <app-label id="color">
                Color
              </app-label>
              <app-select id="color" v-model="form.fields.color" :options="colors" />
            </app-form-field>
            <app-button class="mt-3 transition-colors" :disabled="loading || invalidForm">
              {{ editUuid ? "Update" : "Create" }}
            </app-button>
          </app-form>
        </app-modal>
      </transition>
    </app-box>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { Util as U } from "@/util";
export default {
  name: "Labels",
  data() {
    return {
      colors: [
        "Gray",
        "Red",
        "Orange",
        "Yellow",
        "Teal",
        "Green",
        "Blue",
        "Indigo",
        "Purple",
        "Pink",
      ],
      editUuid: "",
      form: {
        fields: {
          name: "",
          description: "",
          color: "",
        },
      },
      labels: [],
      showModal: false,
    };
  },
  computed: {
    ...mapGetters(["loggedIn"]),
    ...mapState(["loading", "minimal"]),
    cLabels() {
      return [...this.labels]
        .sort((a, b) => {
          const aName = a.name.toLowerCase();
          const bName = b.name.toLowerCase();
          return aName < bName ? -1 : aName > bName ? 1 : 0;
        })
        .map((i) => {
          i.options = ["Edit", "Delete"];
          return i;
        });
    },
    invalidForm() {
      const { name, color } = this.form.fields;
      return name === "" || color === "";
    },
  },
  async created() {
    if (U.redirect(this.loggedIn)) return this.$router.push("/");
    await this.getData();
  },
  methods: {
    async getData() {
      const { labels } = await U.api({ url: "api/labels" });
      this.labels = labels;
    },
    async handleChoice(uuid, option) {
      if (option === "Edit") {
        this.openModal({ type: "edit", uuid });
      } else if (option === "Delete") {
        this.labels = U.delete("label", this.labels, uuid);
      }
    },
    openModal({ type = "new", uuid = "" } = {}) {
      this.editUuid = type === "edit" ? uuid : "";
      let label;
      if (type !== "new") {
        label = { ...this.cLabels.find((i) => i.uuid === uuid) };
      }
      for (const field in this.form.fields) {
        if (type === "new") {
          let defaultValue = "";
          this.form.fields[field] = defaultValue;
        } else if (label[field] !== undefined) {
          this.form.fields[field] = label[field];
        }
      }
      this.showModal = true;
    },
    submitForm() {
      if (this.invalidForm) return;
      let { name, description, color } = this.form.fields;
      const obj = {
        name,
        description,
        color,
      };
      if (this.editUuid) {
        const uuid = this.editUuid;
        this.labels = U.patch("label", this.labels, uuid, obj);
      } else {
        this.labels = U.post("label", this.labels, obj);
        U.scrollToLastAdded();
      }
      this.closeModal();
    },
    closeModal() {
      this.showModal = false;
    },
  },
};
</script>
